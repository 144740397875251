import { Fragment, useCallback, useState } from "react";
import { useRouter } from "next/router";

import Brand from "../Typography/Brand/Brand";
import UserCard, { UserImage } from "../UserCard/UserCard";
import { AuthOption, useAuth } from "@/context/AuthContext";
import { useDiffusion } from "@/context/SDContext";
import { User } from "@/models/models";
import styles from "./Navbar.module.scss";

type NavMenuProps = {
    user: User;
    selectedOption: string;
    redirectToHome: () => void;
    redirectToProfile: () => void;
    handleAuthActionClick: (authOption: AuthOption) => Promise<void>;
    isMobile?: boolean;
    closeMenu?: () => void;
    showBrand?: boolean;
};

const NavMenu = (props: NavMenuProps) => {
    const [showUserCard, setShowUserCard] = useState(false);

    return (
        <Fragment>
            <div className={styles.brand}>
                <Brand onClick={props.redirectToHome} />
            </div>

            <nav className={styles.auth}>
                <span
                    className={styles.avatarImage}
                    onClick={() => setShowUserCard(!showUserCard)}
                >
                    <UserImage />
                </span>

                <div className={styles.userCardContainer}>
                    <UserCard
                        showCard={showUserCard}
                        setShowCard={setShowUserCard}
                    />
                </div>
            </nav>
        </Fragment>
    );
};

interface NavbarProps {
    showBrand?: boolean;
}

const Navbar = (props: NavbarProps) => {
    const router = useRouter();
    const { user, setAuthOption } = useAuth();
    const { selectedOption } = useDiffusion();

    const redirectToHome = useCallback(async () => {
        router.push("/");
    }, [user, router]);

    const redirectToProfile = useCallback(async () => {
        router.push("/profile");
    }, [router]);

    const handleAuthActionClick = useCallback(
        async (authOption: AuthOption) => {
            if (router.pathname !== "") {
                router.push("/");
                setAuthOption(authOption);
            }
            setAuthOption(authOption);
        },
        [router, setAuthOption]
    );

    return (
        <div className={styles.navbarContainer}>
                <NavMenu
                    user={user}
                    selectedOption={selectedOption}
                    redirectToHome={redirectToHome}
                    redirectToProfile={redirectToProfile}
                    handleAuthActionClick={handleAuthActionClick}
                    isMobile={false}
                    showBrand={props.showBrand}
                />
        </div>
    );
};

export default Navbar;
