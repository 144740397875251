import EditProfileForm from "../EditProfileForm/EditProfileForm";
import { UserIcon } from "../icons/user";
import { CloseIcon } from "../icons/close";
import { logout, removeUserInfo } from "@/services/users";
import { useAuth } from "@/context/AuthContext";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import { useToastContext } from "@/context/ToastContext";
import styles from "./UserProfile.module.scss";

export const UserProfile = () => {
    const { user } = useAuth();
    const { showSuccessAlert, showWarningAlert, showErrorAlert } =
        useToastContext();
    const { isMobile } = useWindowDimensions();

    const confirmRemove = () => {
        showWarningAlert(
            "Are you sure you want to delete your account?",
            "Confirm",
            handleRemove
        );
    };

    const handleRemove = () => {
        removeUserInfo(user.email)
            .then((response: any) => {
                if (response) {
                    showSuccessAlert("User removed");
                    logout().then(() => {
                        window.location.href = "/";
                    });
                }
            })
            .catch((error: any) => {
                showErrorAlert(error.message || "Error removing user");
            });
    };

    const DeleteAccount = (
        <div className={styles.navItem} onClick={confirmRemove}>
            <CloseIcon width={"16"} height={"16"} color={"#B3005E"} />
            <p className="base-2 main">Delete account</p>
        </div>
    );

    return (
        <div className={styles.userProfileContainer}>
            <EditProfileForm />
            {DeleteAccount}
        </div>
    );
};

export default UserProfile;
